<template>
  <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.723 0.799999V3.572H3.263V15.5H0.365V0.799999H8.723ZM16.094 5H18.803V15.5H16.094V14.261C15.282 15.283 14.141 15.794 12.671 15.794C11.271 15.794 10.067 15.262 9.059 14.198C8.065 13.12 7.568 11.804 7.568 10.25C7.568 8.696 8.065 7.387 9.059 6.323C10.067 5.245 11.271 4.706 12.671 4.706C14.141 4.706 15.282 5.217 16.094 6.239V5ZM11.096 12.392C11.642 12.938 12.335 13.211 13.175 13.211C14.015 13.211 14.708 12.938 15.254 12.392C15.814 11.832 16.094 11.118 16.094 10.25C16.094 9.382 15.814 8.675 15.254 8.129C14.708 7.569 14.015 7.289 13.175 7.289C12.335 7.289 11.642 7.569 11.096 8.129C10.55 8.675 10.277 9.382 10.277 10.25C10.277 11.118 10.55 11.832 11.096 12.392Z"
      fill="#232426" />
  </svg>
</template>

<script>
export default {
  name: 'GaIcon'
}
</script>
