<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 8H17.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M7.5 13H13.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M16.5 18H16.2132L15.97 18.152L9.5 22.1958V19V18H8.5H5.5C3.84315 18 2.5 16.6569 2.5 15V6C2.5 4.34315 3.84315 3 5.5 3H19.5C21.1569 3 22.5 4.34315 22.5 6V15C22.5 16.6569 21.1569 18 19.5 18H16.5Z"
      stroke="currentColor"
      stroke-width="2" />
  </svg>
</template>

<script>
export default {
  name: 'FooterMessengerIcon'
}
</script>
