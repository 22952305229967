<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
      stroke="#FE761B"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M8 4.66669V7.66669V7.67975C8 7.87982 8.1 8.06669 8.26653 8.17769L10 9.33335"
      stroke="#FE761B"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'ClockIcon'
}
</script>
