<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1282_2718)">
      <path
        d="M8.69999 19.7188H22.5V15.8492H8.69999V19.7188ZM2.49998 8.15079H6.70001V4.28123H2.49998V8.15079ZM2.49998 13.8484H6.70001V10.182H2.49998V13.8484ZM2.49998 19.7188H6.70001V15.8492H2.49998V19.7188ZM8.69999 13.8484H22.5V10.182H8.69999V13.8484ZM8.69999 8.15079H22.5V4.28123H8.69999V8.15079ZM2.49998 21.75C1.95998 21.75 1.49165 21.5486 1.09499 21.1457C0.69833 20.7429 0.5 20.2672 0.5 19.7188V4.28123C0.5 3.73279 0.69833 3.25714 1.09499 2.85429C1.49165 2.45143 1.95998 2.25 2.49998 2.25H22.5C23.04 2.25 23.5083 2.45143 23.905 2.85429C24.3017 3.25714 24.5 3.73279 24.5 4.28123V19.7188C24.5 20.2672 24.3017 20.7429 23.905 21.1457C23.5083 21.5486 23.04 21.75 22.5 21.75H2.49998Z"
        fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_1282_2718">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'FooterHistoryIcon'
}
</script>
