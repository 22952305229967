<template>
  <v-icon size="36" class="header-logo">$mainLogo</v-icon>
  <div>
    <p class="header-title text-primary">Мой урожай</p>
    <p class="header-text text-primary text-center">проект АгроПоиска</p>
  </div>
</template>

<style>
.header-logo {
  margin-right: 8px;
}
.header-title {
  font-size: 19px;
  font-weight: 700;
}
.header-text {
  font-size: 10px;
  font-weight: 400;
  line-height: 150%;
}
</style>
