<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1282_2711)">
      <g clip-path="url(#clip1_1282_2711)">
        <path
          d="M20.6667 16H13.4667C13.2667 16.6 12.9084 17.0833 12.3917 17.45C11.8751 17.8167 11.3001 18 10.6667 18C10.0334 18 9.46258 17.8167 8.95425 17.45C8.44591 17.0833 8.08342 16.6 7.86675 16H6.66675C5.83341 16 5.12508 15.7083 4.54175 15.125C3.95841 14.5417 3.66675 13.8333 3.66675 13V7H9.66675V4H3.66675V2H16.6667C17.2167 2 17.6876 2.19583 18.0792 2.5875C18.4709 2.97917 18.6667 3.45 18.6667 4V14H20.6667V16ZM11.6667 7H16.6667V4H11.6667V7ZM10.6667 16C10.9501 16 11.1876 15.9042 11.3792 15.7125C11.5709 15.5208 11.6667 15.2833 11.6667 15C11.6667 14.7167 11.5709 14.4792 11.3792 14.2875C11.1876 14.0958 10.9501 14 10.6667 14C10.3834 14 10.1459 14.0958 9.95425 14.2875C9.76258 14.4792 9.66675 14.7167 9.66675 15C9.66675 15.2833 9.76258 15.5208 9.95425 15.7125C10.1459 15.9042 10.3834 16 10.6667 16ZM7.86675 14C8.08342 13.4 8.44591 12.9167 8.95425 12.55C9.46258 12.1833 10.0334 12 10.6667 12C11.3001 12 11.8751 12.1833 12.3917 12.55C12.9084 12.9167 13.2667 13.4 13.4667 14H16.6667V9H5.66675V13C5.66675 13.2833 5.76258 13.5208 5.95425 13.7125C6.14591 13.9042 6.38341 14 6.66675 14H7.86675ZM7.86675 9H5.66675H16.6667H7.86675Z"
          fill="currentColor" />
        <path d="M4.16675 18H20.1667L22.1667 20H2.16675L4.16675 18Z" fill="#232426" />
        <path d="M0.166748 21H24.1667V23H0.166748V21Z" fill="#232426" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1282_2711">
        <rect width="24" height="24" fill="white" transform="translate(0.166748)" />
      </clipPath>
      <clipPath id="clip1_1282_2711">
        <rect width="24" height="24" fill="white" transform="translate(0.166748)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'FooterTareIcon'
}
</script>
