<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icons 55">
      <path
        id="Vector"
        d="M19.3125 5H4.6875C3.75549 5 3 5.78347 3 6.75V17.25C3 18.2165 3.75549 19 4.6875 19H19.3125C20.2445 19 21 18.2165 21 17.25V6.75C21 5.78347 20.2446 5 19.3125 5ZM15.8555 16.386C15.7543 16.5598 15.572 16.6666 15.3752 16.6666H6.41116C6.21592 16.6666 6.03538 16.5617 5.93252 16.39C5.83019 16.2179 5.82052 16.0038 5.90716 15.8225L9.23652 8.89247C9.35065 8.65158 9.59147 8.4999 9.86317 8.4999C10.1428 8.4999 10.3862 8.65863 10.4987 8.91527L11.9252 12.1802L12.3657 11.3024C12.4625 11.1093 12.7358 10.9349 12.9456 10.9349C13.1538 10.9349 13.367 11.0399 13.5453 11.2301C13.5829 11.2704 13.6156 11.3171 13.6409 11.3672L15.873 15.8136C15.9651 15.9953 15.9579 16.2117 15.8554 16.3861L15.8555 16.386ZM16.4996 12C15.2565 12 14.2496 10.9559 14.2496 9.66667C14.2496 8.37747 15.2565 7.33333 16.4996 7.33333C17.7428 7.33333 18.7496 8.37747 18.7496 9.66667C18.7496 10.9559 17.7428 12 16.4996 12Z"
        fill="#909090" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TrashIcon'
}
</script>
