<template>
  <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icon24">
      <path
        id="Vector"
        d="M6.75 14.625C6.75 32.6432 21.3567 47.25 39.375 47.25C40.8735 47.25 42.3486 47.149 43.7933 46.9535C45.8431 46.6758 47.25 44.8407 47.25 42.7725V36.9934C47.25 35.0566 46.0105 33.3369 44.1731 32.7244L37.0892 30.3631C35.2881 29.7628 33.3567 30.8228 32.8963 32.6648C32.2819 35.123 29.2207 35.9707 27.4291 34.1791L19.8208 26.5709C18.0293 24.7793 18.8771 21.7182 21.3352 21.1037C23.1773 20.6432 24.2372 18.712 23.6369 16.9107L21.2757 9.82696C20.6631 7.98943 18.9435 6.75 17.0066 6.75H11.2276C9.1593 6.75 7.32416 8.15699 7.04666 10.2066C6.85103 11.6515 6.75 13.1265 6.75 14.625Z"
        stroke="#0075D7"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'EmailLargeIcon'
}
</script>
