<template>
  <svg width="34" height="22" viewBox="0 0 34 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Frame 123">
      <path
        id="Vector"
        d="M6.79775 14.7035C4.73249 14.7035 3.05762 16.3373 3.05762 18.3521C3.05762 20.3667 4.73249 22 6.79775 22C6.89712 22 6.99484 21.994 7.09184 21.9854C8.90927 21.8471 10.361 20.4442 10.521 18.6762C10.5314 18.5698 10.5376 18.4615 10.5376 18.3518C10.5376 16.3372 8.86297 14.7034 6.79771 14.7034L6.79775 14.7035Z"
        fill="#909090" />
      <path
        id="Vector_2"
        d="M10.8797 0.000178031V2.98502H4.34558L0 7.70523V18.2414H1.70401C1.7652 15.5492 4.02446 13.3766 6.79832 13.3766C9.572 13.3766 11.8317 15.5492 11.8929 18.2414H22.1057C22.1668 15.5492 24.4259 13.3766 27.2 13.3766C29.9741 13.3766 32.2333 15.5492 32.2943 18.2414H34V0L10.8797 0.000178031ZM10.8797 8.6234H2.49257L5.41871 5.30678H10.8799L10.8797 8.6234Z"
        fill="#909090" />
      <path
        id="Vector_3"
        d="M30.9393 18.3518C30.9393 20.3666 29.2646 21.9999 27.1991 21.9999C25.1336 21.9999 23.459 20.3666 23.459 18.3518C23.459 16.3367 25.1336 14.7034 27.1991 14.7034C29.2646 14.7034 30.9393 16.3367 30.9393 18.3518Z"
        fill="#909090" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TruckIcon'
}
</script>
