<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.99992 13.3332V5.79986C1.81103 5.67764 1.6527 5.52208 1.52492 5.33319C1.39714 5.1443 1.33325 4.92208 1.33325 4.66652V2.66652C1.33325 2.29986 1.46381 1.98597 1.72492 1.72486C1.98603 1.46375 2.29992 1.33319 2.66659 1.33319H13.3333C13.6999 1.33319 14.0138 1.46375 14.2749 1.72486C14.536 1.98597 14.6666 2.29986 14.6666 2.66652V4.66652C14.6666 4.92208 14.6027 5.1443 14.4749 5.33319C14.3471 5.52208 14.1888 5.67764 13.9999 5.79986V13.3332C13.9999 13.6999 13.8694 14.0137 13.6083 14.2749C13.3471 14.536 13.0333 14.6665 12.6666 14.6665H3.33325C2.96659 14.6665 2.6527 14.536 2.39159 14.2749C2.13047 14.0137 1.99992 13.6999 1.99992 13.3332ZM3.33325 5.99986V13.3332H12.6666V5.99986H3.33325ZM13.3333 4.66652V2.66652H2.66659V4.66652H13.3333ZM5.99992 9.33319H9.99992V7.99986H5.99992V9.33319Z"
      fill="#6B6B6B" />
  </svg>
</template>

<script>
export default {
  name: 'AnalyticsTwoIcon'
}
</script>
