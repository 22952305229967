<template>
  <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icon24">
      <g id="Eye">
        <path
          id="Vector"
          d="M11.5 5.53052C4.3125 5.53052 1.4375 12 1.4375 12C1.4375 12 4.3125 18.468 11.5 18.468C18.6875 18.468 21.5625 12 21.5625 12C21.5625 12 18.6875 5.53052 11.5 5.53052Z"
          stroke="#1B1C1E"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round" />
        <path
          id="Vector_2"
          d="M11.5 15.5938C13.4848 15.5938 15.0938 13.9848 15.0938 12C15.0938 10.0152 13.4848 8.40625 11.5 8.40625C9.51523 8.40625 7.90625 10.0152 7.90625 12C7.90625 13.9848 9.51523 15.5938 11.5 15.5938Z"
          stroke="#1B1C1E"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'EyeShowIcon'
}
</script>
