<template>
  <svg width="41" height="17" viewBox="0 0 41 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Frame 8388" clip-path="url(#clip0_244_27586)">
      <path
        id="Vector"
        d="M40.2025 9.43921H32.7895V-7H1.02885V9.16018H0.49935C0.224814 9.16018 0 9.38691 0 9.66377V10.9119C0 11.1888 0.224823 11.4155 0.49935 11.4155H1.02917V11.6765H10.4612C10.4612 14.6166 12.8245 17 15.7398 17C18.6551 17 21.0184 14.6166 21.0184 11.6765H32.7904V11.0468H40.2033C40.6414 11.0468 41 10.6852 41 10.2433C41.0003 9.80121 40.6414 9.43928 40.2033 9.43928L40.2025 9.43921ZM15.7391 14.9636C13.9396 14.9636 12.4804 13.4922 12.4804 11.6771C12.4804 9.86166 13.9394 8.39071 15.7391 8.39071C17.5393 8.39071 18.9979 9.86207 18.9979 11.6771C18.9979 13.4916 17.5387 14.9636 15.7391 14.9636Z"
        fill="#909090" />
    </g>
    <defs>
      <clipPath id="clip0_244_27586">
        <rect width="41" height="17" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'TrailIcon'
}
</script>
