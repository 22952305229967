<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.33325 14.6666C1.33325 13.4333 1.49714 12.3805 1.82492 11.5083C2.1527 10.6361 2.57214 9.91109 3.08325 9.33331C3.59436 8.75553 4.16659 8.30831 4.79992 7.99165C5.43325 7.67498 6.05547 7.45553 6.66658 7.33331V5.33331C5.14436 5.14442 3.87492 4.67498 2.85825 3.92498C1.84159 3.17498 1.33325 2.31109 1.33325 1.33331H14.6666C14.6666 2.31109 14.1583 3.17498 13.1416 3.92498C12.1249 4.67498 10.8555 5.14442 9.33325 5.33331V7.33331C9.94436 7.45553 10.5666 7.67498 11.1999 7.99165C11.8333 8.30831 12.4055 8.75553 12.9166 9.33331C13.4277 9.91109 13.8471 10.6361 14.1749 11.5083C14.5027 12.3805 14.6666 13.4333 14.6666 14.6666H10.6666V13.3333H13.2499C13.0499 11.6444 12.4194 10.4222 11.3583 9.66665C10.2971 8.91109 9.1777 8.53331 7.99992 8.53331C6.82214 8.53331 5.7027 8.91109 4.64159 9.66665C3.58047 10.4222 2.94992 11.6444 2.74992 13.3333H5.33325V14.6666H1.33325ZM7.99992 4.08331C9.01103 4.08331 9.91103 3.9472 10.6999 3.67498C11.4888 3.40276 12.1166 3.06665 12.5833 2.66665H3.41659C3.88325 3.06665 4.51103 3.40276 5.29992 3.67498C6.08881 3.9472 6.98881 4.08331 7.99992 4.08331ZM7.99992 14.6666C7.63325 14.6666 7.31936 14.5361 7.05825 14.275C6.79714 14.0139 6.66658 13.7 6.66658 13.3333C6.66658 13.1444 6.7027 12.9722 6.77492 12.8166C6.84714 12.6611 6.94436 12.5222 7.06659 12.4C7.33325 12.1333 7.78325 11.8528 8.41658 11.5583C9.04992 11.2639 9.79992 10.9666 10.6666 10.6666C10.3555 11.5333 10.0555 12.2833 9.76658 12.9166C9.4777 13.55 9.19992 14 8.93325 14.2666C8.81103 14.3889 8.67214 14.4861 8.51658 14.5583C8.36103 14.6305 8.18881 14.6666 7.99992 14.6666Z"
      fill="#6B6B6B" />
  </svg>
</template>

<script>
export default {
  name: 'AnalyticsOneIcon'
}
</script>
