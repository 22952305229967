<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1282_2701)">
      <path
        d="M4.05475 21.779H8.72202V13.5558H16.2785V21.779H20.9457V9.11075L12.5002 2.77664L4.05475 9.11075V21.779ZM1.83228 24.0015V7.99951L12.5002 -0.00146484L23.1682 7.99951V24.0015H14.056V15.7782H10.9445V24.0015H1.83228Z"
        fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_1282_2701">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'FooterHomeIcon'
}
</script>
