<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.00065 14.6666C7.08954 14.6666 6.22843 14.4916 5.41732 14.1416C4.60621 13.7916 3.89787 13.3138 3.29232 12.7083C2.68676 12.1027 2.20898 11.3944 1.85898 10.5833C1.50898 9.77214 1.33398 8.91103 1.33398 7.99992C1.33398 7.0777 1.50898 6.21381 1.85898 5.40825C2.20898 4.6027 2.68676 3.89714 3.29232 3.29159C3.89787 2.68603 4.60621 2.20825 5.41732 1.85825C6.22843 1.50825 7.08954 1.33325 8.00065 1.33325C8.92287 1.33325 9.78676 1.50825 10.5923 1.85825C11.3979 2.20825 12.1034 2.68603 12.709 3.29159C13.3145 3.89714 13.7923 4.6027 14.1423 5.40825C14.4923 6.21381 14.6673 7.0777 14.6673 7.99992C14.6673 8.91103 14.4923 9.77214 14.1423 10.5833C13.7923 11.3944 13.3145 12.1027 12.709 12.7083C12.1034 13.3138 11.3979 13.7916 10.5923 14.1416C9.78676 14.4916 8.92287 14.6666 8.00065 14.6666ZM8.00065 11.9999C9.11176 11.9999 10.0562 11.611 10.834 10.8333C11.6118 10.0555 12.0007 9.11103 12.0007 7.99992C12.0007 6.88881 11.6118 5.94436 10.834 5.16658C10.0562 4.38881 9.11176 3.99992 8.00065 3.99992C6.88954 3.99992 5.9451 4.38881 5.16732 5.16658C4.38954 5.94436 4.00065 6.88881 4.00065 7.99992C4.00065 9.11103 4.38954 10.0555 5.16732 10.8333C5.9451 11.611 6.88954 11.9999 8.00065 11.9999Z"
      fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'TabAllTimeIcon'
}
</script>
