<template>
  <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icon64">
      <g id="Camera">
        <path
          id="Vector"
          d="M52 52.5H12C10.9391 52.5 9.92172 52.0786 9.17157 51.3284C8.42143 50.5783 8 49.5609 8 48.5V20.5C8 19.4391 8.42143 18.4217 9.17157 17.6716C9.92172 16.9214 10.9391 16.5 12 16.5H19.9985L23.9985 10.5H39.9985L43.9985 16.5H52C53.0609 16.5 54.0783 16.9214 54.8284 17.6716C55.5786 18.4217 56 19.4391 56 20.5V48.5C56 49.5609 55.5786 50.5783 54.8284 51.3284C54.0783 52.0786 53.0609 52.5 52 52.5Z"
          stroke="#909090"
          stroke-width="4"
          stroke-linejoin="round" />
        <path
          id="Vector_2"
          d="M32 43.5C37.5228 43.5 42 39.0228 42 33.5C42 27.9772 37.5228 23.5 32 23.5C26.4772 23.5 22 27.9772 22 33.5C22 39.0228 26.4772 43.5 32 43.5Z"
          stroke="#909090"
          stroke-width="4"
          stroke-linejoin="round" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CameraIcon'
}
</script>
