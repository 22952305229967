<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="percent_FILL0_wght400_GRAD0_opsz24 1">
      <path
        id="Vector"
        d="M7.5 11.5C6.53333 11.5 5.70833 11.1583 5.025 10.475C4.34167 9.79167 4 8.96667 4 8C4 7.03333 4.34167 6.20833 5.025 5.525C5.70833 4.84167 6.53333 4.5 7.5 4.5C8.46667 4.5 9.29167 4.84167 9.975 5.525C10.6583 6.20833 11 7.03333 11 8C11 8.96667 10.6583 9.79167 9.975 10.475C9.29167 11.1583 8.46667 11.5 7.5 11.5ZM7.5 9.5C7.91667 9.5 8.27083 9.35417 8.5625 9.0625C8.85417 8.77083 9 8.41667 9 8C9 7.58333 8.85417 7.22917 8.5625 6.9375C8.27083 6.64583 7.91667 6.5 7.5 6.5C7.08333 6.5 6.72917 6.64583 6.4375 6.9375C6.14583 7.22917 6 7.58333 6 8C6 8.41667 6.14583 8.77083 6.4375 9.0625C6.72917 9.35417 7.08333 9.5 7.5 9.5ZM16.5 20.5C15.5333 20.5 14.7083 20.1583 14.025 19.475C13.3417 18.7917 13 17.9667 13 17C13 16.0333 13.3417 15.2083 14.025 14.525C14.7083 13.8417 15.5333 13.5 16.5 13.5C17.4667 13.5 18.2917 13.8417 18.975 14.525C19.6583 15.2083 20 16.0333 20 17C20 17.9667 19.6583 18.7917 18.975 19.475C18.2917 20.1583 17.4667 20.5 16.5 20.5ZM16.5 18.5C16.9167 18.5 17.2708 18.3542 17.5625 18.0625C17.8542 17.7708 18 17.4167 18 17C18 16.5833 17.8542 16.2292 17.5625 15.9375C17.2708 15.6458 16.9167 15.5 16.5 15.5C16.0833 15.5 15.7292 15.6458 15.4375 15.9375C15.1458 16.2292 15 16.5833 15 17C15 17.4167 15.1458 17.7708 15.4375 18.0625C15.7292 18.3542 16.0833 18.5 16.5 18.5ZM5.4 20.5L4 19.1L18.6 4.5L20 5.9L5.4 20.5Z"
        fill="#1B1C1E" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PercentIcon'
}
</script>
