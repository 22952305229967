<template>
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="alert icon">
      <path
        id="Vector"
        d="M8.5 2C5.188 2 2.5 4.688 2.5 8C2.5 11.312 5.188 14 8.5 14C11.812 14 14.5 11.312 14.5 8C14.5 4.688 11.812 2 8.5 2ZM6.874 10.574L4.72 8.42C4.66445 8.36445 4.62039 8.2985 4.59032 8.22593C4.56026 8.15335 4.54479 8.07556 4.54479 7.997C4.54479 7.91844 4.56026 7.84065 4.59032 7.76807C4.62039 7.6955 4.66445 7.62955 4.72 7.574C4.77555 7.51845 4.8415 7.47439 4.91407 7.44432C4.98665 7.41426 5.06444 7.39879 5.143 7.39879C5.22156 7.39879 5.29935 7.41426 5.37193 7.44432C5.4445 7.47439 5.51045 7.51845 5.566 7.574L7.3 9.302L11.428 5.174C11.5402 5.06181 11.6923 4.99879 11.851 4.99879C12.0097 4.99879 12.1618 5.06181 12.274 5.174C12.3862 5.28619 12.4492 5.43834 12.4492 5.597C12.4492 5.75566 12.3862 5.90781 12.274 6.02L7.72 10.574C7.66449 10.6296 7.59856 10.6738 7.52597 10.7039C7.45339 10.734 7.37558 10.7495 7.297 10.7495C7.21842 10.7495 7.14061 10.734 7.06803 10.7039C6.99544 10.6738 6.92951 10.6296 6.874 10.574Z"
        fill="#07C168" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SuccessIcon'
}
</script>
