<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1301_19171)">
      <circle opacity="0.74" cx="12" cy="12" r="12" fill="white" />
      <path d="M10 17L15 12L10 7" stroke="#232426" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1301_19171">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'ArrowRightIcon'
}
</script>
