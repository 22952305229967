<template>
  <svg width="23" height="13" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 13H8.25L3.7 7.325V13H0.475V0.5H3.7V6L8 0.5H11.85L6.825 6.675L12 13ZM22.1598 0.5V3.6H16.8348V13H13.6098V0.5H22.1598Z"
      fill="#232426" />
  </svg>
</template>

<script>
export default {
  name: 'KgIcon'
}
</script>
