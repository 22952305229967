<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icon24">
      <g id="Trash">
        <path
          id="Vector"
          d="M21 5H3"
          stroke="#232426"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round" />
        <path
          id="Vector_2"
          d="M9.75 9.75V15.75"
          stroke="#232426"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round" />
        <path
          id="Vector_3"
          d="M14.25 9.75V15.75"
          stroke="#232426"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round" />
        <path
          id="Vector_4"
          d="M19 6L18 20H6L5.00005 6"
          stroke="#232426"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round" />
        <path
          id="Vector_5"
          d="M16 5V3.5C16 3.10218 15.8314 2.72064 15.5314 2.43934C15.2313 2.15804 14.8243 2 14.4 2H9.6C9.17565 2 8.76869 2.15804 8.46863 2.43934C8.16857 2.72064 8 3.10218 8 3.5V5"
          stroke="#232426"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TrashIcon'
}
</script>
