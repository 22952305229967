<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.75732 16.2426L16.2426 7.75733" stroke="white" stroke-width="2" stroke-linecap="round" />
    <path d="M16.2427 16.2426L7.75739 7.75733" stroke="white" stroke-width="2" stroke-linecap="round" />
  </svg>
</template>

<script>
export default {
  name: 'CloseIcon'
}
</script>
