<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.82564 14.3567C4.51138 14.3567 3.44556 15.3964 3.44556 16.6785C3.44556 17.9606 4.51138 19 5.82564 19C5.88888 19 5.95106 18.9961 6.01279 18.9907C7.16934 18.9026 8.09313 18.0099 8.19498 16.8848C8.20162 16.8171 8.20554 16.7482 8.20554 16.6784C8.20554 15.3964 7.13987 14.3567 5.82561 14.3567L5.82564 14.3567Z"
      fill="#909090" />
    <path
      d="M8.42343 5.00011V6.89956H4.26537L1.5 9.90333V16.6082H2.58437C2.62331 14.895 4.06102 13.5124 5.82621 13.5124C7.59128 13.5124 9.02925 14.895 9.06819 16.6082H15.5672C15.6062 14.895 17.0437 13.5124 18.8091 13.5124C20.5744 13.5124 22.0121 14.895 22.0509 16.6082H23.1364V5L8.42343 5.00011ZM8.42343 10.4876H3.08618L4.94827 8.37704H8.42358L8.42343 10.4876Z"
      fill="#909090" />
    <path
      d="M21.1889 16.6784C21.1889 17.9606 20.1232 19 18.8088 19C17.4944 19 16.4287 17.9606 16.4287 16.6784C16.4287 15.3961 17.4944 14.3567 18.8088 14.3567C20.1232 14.3567 21.1889 15.3961 21.1889 16.6784Z"
      fill="#909090" />
  </svg>
</template>

<script>
export default {
  name: 'infoTwoIcon'
}
</script>
