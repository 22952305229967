<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.4168 22.4982H5.58837C4.98653 22.5237 4.4051 22.2814 4.00509 21.8385C3.60511 21.3956 3.42914 20.7994 3.52613 20.2148L5.29472 10.4057C5.43341 9.98181 5.71422 9.61685 6.09128 9.37006C6.46818 9.12342 6.91912 9.00962 7.37035 9.04741H9.73288C8.8115 8.29592 8.24688 7.20361 8.17286 6.02885C8.09867 4.85409 8.52175 3.7016 9.34156 2.84393C10.1615 1.98641 11.3055 1.5 12.5026 1.5C13.6998 1.5 14.8437 1.98641 15.6636 2.84393C16.4834 3.7016 16.9065 4.85409 16.8323 6.02885C16.7583 7.20361 16.1937 8.29592 15.2723 9.04741H17.6349C18.0849 9.01665 18.5326 9.13323 18.908 9.379C19.2835 9.62461 19.5657 9.98565 19.7105 10.4057L21.4791 20.2478C21.5649 20.8271 21.384 21.4141 20.9854 21.8491C20.5868 22.2841 20.0119 22.5221 19.4169 22.4983L19.4168 22.4982ZM12.5026 3.47002C11.883 3.47002 11.2889 3.71197 10.8509 4.1427C10.4128 4.57328 10.1667 5.15736 10.1667 5.76652C10.1667 6.37569 10.4128 6.95973 10.8509 7.39035C11.2889 7.82108 11.883 8.06302 12.5026 8.06302C13.1222 8.06302 13.7162 7.82107 14.1542 7.39035C14.5923 6.95977 14.8384 6.37569 14.8384 5.76652C14.8384 5.15736 14.5923 4.57332 14.1542 4.1427C13.7163 3.71197 13.1222 3.47002 12.5026 3.47002Z"
      fill="#909090" />
  </svg>
</template>

<script>
export default {
  name: 'infoOneIcon'
}
</script>
