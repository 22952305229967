<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.5 22.5L4.5 22.5C3.3954 22.5 2.5 21.4367 2.5 20.125L2.5 13L2.5 9.4375M2.5 9.4375L2.5 5.875C2.5 4.56332 3.3954 3.5 4.5 3.5L6.5 3.5L16.5 3.5L18.5 3.5C19.6046 3.5 20.5 4.56332 20.5 5.875C20.5 5.875 20.5 8.04626 20.5 9.4375L2.5 9.4375Z"
      stroke="#3D3D3D"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path d="M16.5 1.5L16.5 3.5" stroke="#3D3D3D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M6.5 1.5L6.5 3.5" stroke="#3D3D3D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M11.5 1.5L11.5 3.5" stroke="#3D3D3D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M17 21C16.1667 21 15.4583 20.7083 14.875 20.125C14.2917 19.5417 14 18.8333 14 18C14 17.1667 14.2917 16.4583 14.875 15.875C15.4583 15.2917 16.1667 15 17 15C17.8333 15 18.5417 15.2917 19.125 15.875C19.7083 16.4583 20 17.1667 20 18C20 18.8333 19.7083 19.5417 19.125 20.125C18.5417 20.7083 17.8333 21 17 21ZM16.25 14V12H17.75V14H16.25ZM16.25 24V22H17.75V24H16.25ZM20.375 15.675L19.3 14.625L20.725 13.2L21.775 14.275L20.375 15.675ZM13.275 22.775L12.225 21.725L13.65 20.3L14.7 21.35L13.275 22.775ZM21 18.75V17.25H23V18.75H21ZM11 18.75V17.25H13V18.75H11ZM20.725 22.775L19.325 21.35L20.375 20.3L21.8 21.7L20.725 22.775ZM13.625 15.7L12.225 14.275L13.275 13.225L14.7 14.625L13.625 15.7Z"
      fill="#3D3D3D" />
  </svg>
</template>

<script>
export default {
  name: 'CalendarIcon'
}
</script>
