<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 21C4.45 21 3.979 20.8043 3.587 20.413C3.19567 20.021 3 19.55 3 19V5.00002C3 4.45002 3.19567 3.97902 3.587 3.58702C3.979 3.19568 4.45 3.00002 5 3.00002H12V5.00002H5V19H12V21H5ZM16 17L14.625 15.55L17.175 13H9V11H17.175L14.625 8.45002L16 7.00002L21 12L16 17Z"
      fill="#909090" />
  </svg>
</template>

<script>
export default {
  name: 'LogoutIcon'
}
</script>
