<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 12C22 11.6212 21.786 11.275 21.4472 11.1056L3.44721 2.10557C3.10216 1.93305 2.68865 1.97438 2.38459 2.21179C2.08052 2.4492 1.94012 2.84034 2.02381 3.21693L3.97561 12L2.02381 20.7831C1.94013 21.1597 2.08052 21.5508 2.38459 21.7882C2.68866 22.0256 3.10217 22.0669 3.44721 21.8944L21.4472 12.8944C21.786 12.725 22 12.3788 22 12ZM5.80217 11L4.43195 4.834L16.7639 11L5.80217 11ZM16.7639 13L4.43195 19.166L5.80217 13L16.7639 13Z"
      fill="#0058A1" />
  </svg>
</template>

<script>
export default {
  name: 'SendButtonIcon'
}
</script>
