<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.55737 23C1.55737 21.1042 1.82425 19.4723 2.358 18.1044C2.89175 16.7365 3.57279 15.6004 4.40111 14.6963C5.22946 13.7921 6.15113 13.0906 7.16613 12.5919C8.18113 12.0931 9.16988 11.765 10.1324 11.6075V8.12499C7.73488 7.82749 5.70633 7.11729 4.04674 5.99438C2.38716 4.87146 1.55737 3.54 1.55737 2H22.5574C22.5574 3.54 21.7276 4.87146 20.068 5.99438C18.4084 7.11729 16.3799 7.82749 13.9824 8.12499V11.6075C14.9449 11.765 15.9336 12.0931 16.9486 12.5919C17.9636 13.0906 18.8853 13.7921 19.7136 14.6963C20.542 15.6004 21.223 16.7365 21.7567 18.1044C22.2905 19.4723 22.5574 21.1042 22.5574 23H16.2574V21.25H20.7111C20.3961 18.59 19.4176 16.5804 17.7755 15.2213C16.1334 13.8621 14.2274 13.1825 12.0574 13.1825C9.88736 13.1825 7.98131 13.8621 6.33923 15.2213C4.69715 16.5804 3.71861 18.59 3.40361 21.25H7.85737V23H1.55737ZM12.0574 6.50626C13.8249 6.50626 15.4407 6.22188 16.9049 5.65313C18.3691 5.08437 21.974 2.7 22.5574 2H1.55737C2.1407 2.7 5.74569 5.08437 7.20986 5.65313C8.67404 6.22188 10.2899 6.50626 12.0574 6.50626ZM12.0574 23C11.4799 23 10.9855 22.7944 10.5742 22.3831C10.163 21.9719 9.95737 21.4775 9.95737 20.9C9.95737 20.6025 10.0142 20.3313 10.128 20.0863C10.2417 19.8413 10.3949 19.6225 10.5874 19.43C11.0074 19.01 11.7249 18.571 12.7399 18.1131C13.7549 17.6552 14.9274 17.1842 16.2574 16.7C15.7557 18.03 15.2803 19.2025 14.8311 20.2175C14.382 21.2325 13.9474 21.95 13.5274 22.37C13.3349 22.5625 13.1161 22.7156 12.8711 22.8294C12.6261 22.9431 12.3549 23 12.0574 23Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.226 6.9999H17.6008C19.1096 5.2093 18.8725 1.78951 16.6115 2.42885C16.1421 2.56161 15.8109 2.71105 15.5902 2.83362C15.7186 2.16986 16.2042 1.85056 16.4092 1.69196C16.643 1.51111 17.0119 1.67328 17.1829 1.44155C17.3537 1.20983 17.0706 1.16809 16.5757 1.02433C16.1174 0.891049 15.049 1.27156 15.1378 3.00942C14.9761 2.88806 14.5794 2.63765 13.841 2.42886C11.5797 1.78944 11.3427 5.2093 12.8517 6.99991L15.226 6.9999ZM16.9655 6.95141C18.2514 5.36021 18.0272 2.41122 16.0536 2.9695C15.1572 3.22296 14.832 3.54583 14.832 3.54583C17.307 2.8048 17.7065 5.02436 16.9655 6.95141ZM8.45256 6.9999H10.5164C11.8277 5.44367 11.6218 2.47125 9.65652 3.02715C9.24868 3.14268 8.96077 3.27252 8.769 3.37891C8.88069 2.80191 9.30267 2.52436 9.48095 2.38669C9.68403 2.22969 10.0046 2.37053 10.1532 2.16914C10.3016 1.96762 10.0555 1.93132 9.62543 1.80663C9.22712 1.6907 8.29874 2.02126 8.37573 3.53177C8.23512 3.42631 7.8904 3.20876 7.24864 3.02725C5.28338 2.47158 5.0775 5.44387 6.38876 7L8.45256 6.9999ZM9.96447 6.95777C11.0821 5.57496 10.8872 3.01182 9.17181 3.49717C8.39291 3.71724 8.11013 3.99813 8.11013 3.99813C10.2612 3.35384 10.6085 5.2831 9.96447 6.95777Z"
      fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'FooterHarvestIcon'
}
</script>
