<template>
  <div class="app-loader">
    <v-progress-circular indeterminate color="primary" />
  </div>
</template>

<style lang="scss" scoped>
.app-loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
