<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icon24">
      <path
        id="Vector 97"
        d="M16 19L8 12L16 5"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ChevronBackIcon'
}
</script>
