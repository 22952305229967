<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icons 55" clip-path="url(#clip0_911_1789)">
      <g id="ArrowLeft">
        <path
          id="Vector"
          d="M20.25 12H3.75"
          stroke="#232426"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round" />
        <path
          id="Vector_2"
          d="M10.5 5.25L3.75 12L10.5 18.75"
          stroke="#232426"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_911_1789">
        <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 24 0)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'ArrowBackIcon'
}
</script>
