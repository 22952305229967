<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.99999 12.6667H12L11.05 6H4.94999L3.99999 12.6667ZM7.99999 4.66667C8.18888 4.66667 8.34721 4.60278 8.47499 4.475C8.60277 4.34722 8.66665 4.18889 8.66665 4C8.66665 3.81111 8.60277 3.65278 8.47499 3.525C8.34721 3.39722 8.18888 3.33333 7.99999 3.33333C7.8111 3.33333 7.65277 3.39722 7.52499 3.525C7.39721 3.65278 7.33332 3.81111 7.33332 4C7.33332 4.18889 7.39721 4.34722 7.52499 4.475C7.65277 4.60278 7.8111 4.66667 7.99999 4.66667ZM9.88332 4.66667H11.05C11.3833 4.66667 11.6722 4.77778 11.9167 5C12.1611 5.22222 12.3111 5.49444 12.3667 5.81667L13.3167 12.4833C13.3722 12.8833 13.2694 13.2361 13.0083 13.5417C12.7472 13.8472 12.4111 14 12 14H3.99999C3.58888 14 3.25277 13.8472 2.99165 13.5417C2.73054 13.2361 2.62777 12.8833 2.68332 12.4833L3.63332 5.81667C3.68888 5.49444 3.83888 5.22222 4.08332 5C4.32777 4.77778 4.61665 4.66667 4.94999 4.66667H6.11665C6.08332 4.55556 6.05554 4.44722 6.03332 4.34167C6.0111 4.23611 5.99999 4.12222 5.99999 4C5.99999 3.44444 6.19443 2.97222 6.58332 2.58333C6.97221 2.19444 7.44443 2 7.99999 2C8.55554 2 9.02777 2.19444 9.41665 2.58333C9.80554 2.97222 9.99999 3.44444 9.99999 4C9.99999 4.12222 9.98888 4.23611 9.96665 4.34167C9.94443 4.44722 9.91666 4.55556 9.88332 4.66667Z"
      fill="#6B6B6B" />
  </svg>
</template>

<script>
export default {
  name: 'AnalyticsThreeIcon'
}
</script>
